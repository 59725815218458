import { createRoute } from '@tanstack/react-router';

import { authenticatedLayout } from '@/app/router/layout';

import { Settings } from './Settings';

export const settingsRoute = createRoute({
  getParentRoute: () => authenticatedLayout,
  path: 'settings',
  component: () => <Settings />,
});
