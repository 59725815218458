import { createRoute } from '@tanstack/react-router';

import { authenticatedLayout } from '@/app/router/layout';

import { pageQueryOptions } from './api';

export const pagesPrivacyRoute = createRoute({
  getParentRoute: () => authenticatedLayout,
  path: 'pages/privacy',
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(pageQueryOptions('privacy')),
}).lazy(() => import('./PrivacyPage').then((d) => d.Route));

export const pagesTermsRoute = createRoute({
  getParentRoute: () => authenticatedLayout,
  path: 'pages/terms',
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(pageQueryOptions('terms')),
}).lazy(() => import('./TermsPage').then((d) => d.Route));

export const pagesReservationPolicyRoute = createRoute({
  getParentRoute: () => authenticatedLayout,
  path: 'pages/reservation-policy',
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(pageQueryOptions('reservation-policies')),
}).lazy(() => import('./ReservationPolicyPage').then((d) => d.Route));
