import { QueryClient } from '@tanstack/react-query';
import {
  Navigate,
  Outlet,
  createRootRouteWithContext,
  createRoute,
  redirect,
} from '@tanstack/react-router';

import { BaseAuthLayout, BaseNotFoundLayout } from '@/common/layouts';
import { NotFound } from '@/common/layouts/NotFound';

import { AuthContext } from '../auth/AuthProvider';

interface RootRouteContext {
  queryClient: QueryClient;
  auth: AuthContext;
}

export const rootRoute = createRootRouteWithContext<RootRouteContext>()({
  component: () => <Outlet />,
  notFoundComponent: BaseNotFoundLayout,
});

export const authenticatedLayout = createRoute({
  getParentRoute: () => rootRoute,
  id: 'auth',
  beforeLoad: ({ context: { auth } }) => {
    if (!auth.user) {
      throw redirect({
        to: '/login',
      });
    }
  },
  component: () => (
    <BaseAuthLayout>
      <Outlet />
    </BaseAuthLayout>
  ),
  notFoundComponent: () => (
    <BaseAuthLayout>
      <NotFound />
    </BaseAuthLayout>
  ),
});

export const indexRoute = createRoute({
  getParentRoute: () => authenticatedLayout,
  path: '/',
  component: () => <Navigate to="/hotels" />,
});
