import { Navigate, createRoute, redirect } from '@tanstack/react-router';

import { authenticatedLayout } from '@/app/router/layout';

import { hotelSettingsQueryOptions, hotelsQueryOptions } from './api';
import { HotelFacilities } from './HotelFacilities';
import { HotelLayout } from './HotelLayout';
import { HotelListLayout } from './HotelListLayout';
import { HotelRoom } from './HotelRoom';

const hotelListRoute = createRoute({
  getParentRoute: () => authenticatedLayout,
  path: 'hotels',
  component: HotelListLayout,
});

const hotelListIndexRoute = createRoute({
  getParentRoute: () => hotelListRoute,
  path: '/',
  beforeLoad: ({ context: { auth } }) => {
    if (auth.user && auth.user.role === 'editor') {
      throw redirect({
        to: '/news',
      });
    }
  },
  loader: ({ context: { queryClient } }) => {
    queryClient.ensureQueryData(hotelsQueryOptions);
    queryClient.ensureQueryData(hotelSettingsQueryOptions);
  },
}).lazy(() => import('./HotelList').then((d) => d.Route));

const hotelRoute = createRoute({
  getParentRoute: () => hotelListRoute,
  path: '$hotelId',
  component: HotelLayout,
});

const hotelIndexRoute = createRoute({
  getParentRoute: () => hotelRoute,
  path: '/',
  component: () => {
    const { hotelId } = hotelRoute.useParams();
    return <Navigate to="/hotels/$hotelId/about" params={{ hotelId }} />;
  },
});

const hotelAboutRoute = createRoute({
  getParentRoute: () => hotelRoute,
  path: 'about',
}).lazy(() => import('./HotelAbout').then((d) => d.Route));

const hotelRoomRoute = createRoute({
  getParentRoute: () => hotelRoute,
  path: 'rooms',
  component: HotelRoom,
});

const hotelFacilitiesRoute = createRoute({
  getParentRoute: () => hotelRoute,
  path: 'facilities',
  component: HotelFacilities,
});

const hotelEventsRoute = createRoute({
  getParentRoute: () => hotelRoute,
  path: 'events',
}).lazy(() => import('./HotelEvents').then((d) => d.Route));

const hotelAttractionsRoute = createRoute({
  getParentRoute: () => hotelRoute,
  path: 'attractions',
}).lazy(() => import('./HotelAttractions').then((d) => d.Route));

export const hotelsRoute = hotelListRoute.addChildren([
  hotelListIndexRoute,
  hotelRoute.addChildren([
    hotelIndexRoute,
    hotelAboutRoute,
    hotelRoomRoute,
    hotelFacilitiesRoute,
    hotelEventsRoute,
    hotelAttractionsRoute,
  ]),
]);
