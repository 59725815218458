import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { zodResolver } from '@hookform/resolvers/zod';

import { User } from '@/app/auth/AuthProvider';
import { useAuth } from '@/app/auth/useAuth';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

import { useUpdateUserMutation } from './api';
import { UsersFormSchema } from './schemas';

export function UserForm() {
  const { user, setUser } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useForm<UsersFormSchema>({
    resolver: zodResolver(UsersFormSchema),
    defaultValues: {
      displayName: user?.name ?? '',
      email: user?.email ?? '',
    },
  });

  const updateMutation = useUpdateUserMutation(
    () => {
      toast('Success', {
        className: 'bg-green-500 text-white',
        duration: 2000,
      });
      setIsSubmitting(false);
      setUser((prev) => ({
        ...(prev as User),
        name: form.getValues('displayName'),
        email: form.getValues('email'),
      }));
    },
    (error) => {
      toast('Something went wrong.', {
        className: 'bg-red-500 text-white',
        duration: 2500,
      });
      console.error('An error has occured: ', error);
      setIsSubmitting(false);
    },
    user?.uid,
  );

  const onSubmit = async (data: UsersFormSchema) => {
    setIsSubmitting(true);
    updateMutation.mutate(data);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <FormField
          control={form.control}
          name="displayName"
          render={({ field, fieldState: { error } }) => (
            <FormItem>
              <FormLabel>Display Name</FormLabel>
              <FormControl>
                <Input
                  className={`${
                    error
                      ? 'border-2 border-red-500 focus-visible:outline-none'
                      : 'focus-visible:ring-2 '
                  }`}
                  {...field}
                />
              </FormControl>
              <FormMessage className="text-xs" />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field, fieldState: { error } }) => (
            <FormItem>
              <FormLabel>Email Address</FormLabel>
              <FormControl>
                <Input
                  className={`${
                    error
                      ? 'border-2 border-red-500 focus-visible:outline-none'
                      : 'focus-visible:ring-2 '
                  }`}
                  {...field}
                />
              </FormControl>
              <FormMessage className="text-xs" />
            </FormItem>
          )}
        />
        <div className="w-7">
          <Button size="sm" disabled={isSubmitting}>
            Save
          </Button>
        </div>
      </form>
    </Form>
  );
}
