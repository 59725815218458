import { Toaster } from 'react-hot-toast';

import { RouterProvider } from '@tanstack/react-router';

import { AuthProvider } from './auth/AuthProvider';
import { useAuth } from './auth/useAuth';
import { router } from './router';

function RouterWithAuth() {
  const auth = useAuth();
  return <RouterProvider router={router} context={{ auth }} />;
}

export function App() {
  return (
    <AuthProvider>
      <RouterWithAuth />
      <Toaster />
    </AuthProvider>
  );
}
