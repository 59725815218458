import { createRoute, redirect } from '@tanstack/react-router';

import { rootRoute } from '@/app/router/layout';

export const loginRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/login',
  beforeLoad: ({ context: { auth } }) => {
    if (auth.user) {
      throw redirect({
        to: '/hotels',
      });
    }
  },
}).lazy(() => import('./Login').then((d) => d.Route));
