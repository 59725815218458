import { useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';

export const BaseNotFoundLayout = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center bg-slate-200">
      <h1 className="mb-4 text-3xl font-bold">Page Not Found</h1>
      <Button onClick={() => navigate({ to: '/' })}>Go back home</Button>
    </div>
  );
};
