import { flushSync } from 'react-dom';

import { Link, useNavigate } from '@tanstack/react-router';

import { api } from '@/app/api';
import { useAuth } from '@/app/auth/useAuth';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

function Header() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await api.post('/logout');
      flushSync(() => {
        logout();
      });
      navigate({ to: '/login' });
    } catch (err) {
      console.error('Logout error', err);
    }
  };

  return (
    <header className="flex justify-between px-10 m-auto size-full h-16 items-center bg-slate-900 text-white sticky top-0 z-50">
      <Link
        to="/"
        className="font-bold tracking-wider text-xl w-52 cursor-pointer"
      >
        copenhagen
      </Link>
      <DropdownMenu>
        <DropdownMenuTrigger className="outline-none">
          <p className="text-sm">{user?.name}</p>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            onClick={() => navigate({ to: '/settings' })}
            className="cursor-pointer"
          >
            Settings
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleLogout} className="cursor-pointer">
            Logout
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </header>
  );
}

export default Header;
