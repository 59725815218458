import { createRoute, redirect } from '@tanstack/react-router';

import { authenticatedLayout } from '@/app/router/layout';

import { usersQueryOptions } from './api';

const userListRoute = createRoute({
  getParentRoute: () => authenticatedLayout,
  path: 'users',
  beforeLoad: ({ context: { auth } }) => {
    if (auth.user && auth.user.role !== 'superadmin') {
      throw redirect({
        to: '/',
        search: {
          redirect: location.href,
        },
      });
    }
  },
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(usersQueryOptions()),
}).lazy(() => import('./UserList').then((d) => d.Route));

export const usersRoute = userListRoute;
