import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { Check, X } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { formatPrice } from '@/lib/helpers';

import { hotelRoomsQueryOptions } from './api';
import RoomForm from './forms/RoomForm';
import SearchabilityForm from './forms/SearchabilityForm';
import { RoomFormSchema, RoomSchema } from './schemas';

const route = getRouteApi('/auth/hotels/$hotelId');

export function HotelRoom() {
  const { hotelId } = route.useParams();
  const [formType, setType] = useState<'create' | 'update'>('create');
  const [formOpen, setFormOpen] = useState(false);
  const [searchabilityForm, setSearchabilityFormOpen] = useState(false);
  const [roomId, setRoomId] = useState<string>();
  const [room, setRoom] = useState<RoomFormSchema>();
  const { isPending, error, data, isRefetching } = useQuery(
    hotelRoomsQueryOptions(hotelId),
  );

  const handleCreateRoom = () => {
    setRoomId(undefined);
    setRoom(undefined);
    setType('create');
    setFormOpen(true);
  };

  const handleViewRoom = (room: RoomSchema) => {
    const { id, images, amenities, children, ...rest } = room;
    setRoomId(id);
    setRoom({
      ...rest,
      images: images?.length ? images : [],
      amenities: amenities.length ? amenities.map((el) => ({ value: el })) : [],
      child: children, // children is reserved keyword in javascript
    });
    setType('update');
    setFormOpen(true);
  };

  return (
    <div className="py-4 flex flex-col gap-4">
      <div className="flex gap-2">
        <div className="flex flex-col flex-1 gap-1">
          <span className="text-lg font-medium">Rooms</span>
          <span className="text-xs">
            Create, update, and delete rooms for the hotel.
          </span>
        </div>

        <Button
          size="sm"
          variant="outline"
          onClick={() => setSearchabilityFormOpen(true)}
        >
          Searchability
        </Button>
        <Button size="sm" onClick={handleCreateRoom}>
          Add Room
        </Button>
      </div>

      <Sheet open={formOpen} onOpenChange={setFormOpen}>
        <SheetContent className="sm:max-w-none w-2/3 overflow-y-scroll scroll">
          <RoomForm
            type={formType}
            closeSheet={() => setFormOpen(false)}
            unsetRoomId={() => setRoomId(undefined)}
            roomId={roomId}
            room={room}
          />
        </SheetContent>
      </Sheet>

      <Sheet open={searchabilityForm} onOpenChange={setSearchabilityFormOpen}>
        <SheetContent className="sm:max-w-none w-2/3 overflow-y-scroll scroll">
          <SearchabilityForm
            closeSheet={() => setSearchabilityFormOpen(false)}
            rooms={data?.rooms ?? []}
          />
        </SheetContent>
      </Sheet>

      {(isPending || isRefetching) && 'Loading...'}
      {error && 'An error has occurred: ' + error.message}

      {!isPending &&
        !isRefetching &&
        data &&
        (data.rooms.length === 0 ? (
          <p>No Rooms</p>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="select-none">Room</TableHead>
                <TableHead className="select-none">Guests</TableHead>
                <TableHead className="select-none text-center">Price</TableHead>
                <TableHead className="select-none text-center">
                  Inventory
                </TableHead>
                <TableHead className="select-none text-center">
                  Available
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.rooms.map((room) => (
                <TableRow
                  className="cursor-pointer hover:bg-slate-200"
                  key={room.name}
                  onClick={() => handleViewRoom(room)}
                >
                  <TableCell>{room.name}</TableCell>
                  <TableCell>
                    {room.adults} adults, {room.children} children
                  </TableCell>
                  <TableCell className="text-center">
                    {formatPrice(parseInt(room.configuration.cost, 10))}
                  </TableCell>
                  <TableCell className="text-center">
                    {room.configuration.inventory}
                  </TableCell>
                  <TableCell className="flex justify-center">
                    {room.configuration.available ? (
                      <Check className="h-5 w-5" />
                    ) : (
                      <X className="h-5 w-5" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ))}
    </div>
  );
}
