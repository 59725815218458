import { QueryClient, queryOptions, useMutation } from '@tanstack/react-query';

import { api } from '@/app/api';

import { PromoFormSchema, PromoResponseSchema } from './schemas';

// APIs
export const fetchPromos = async () => {
  return await api.get<PromoResponseSchema>('/promos').then((res) => res.data);
};

export const createPromo = async (data: PromoFormSchema) => {
  await api.post('/promos', data);
};

export const updatePromo = async (id: string, data: PromoFormSchema) => {
  await api.put(`/promos/${id}`, data);
};

export const deletePromo = async (id: string) => {
  await api.delete(`/promos/${id}`);
};

// Queries and Mutations
export const promosQueryOptions = () =>
  queryOptions({
    queryKey: ['promos'],
    queryFn: () => fetchPromos(),
  });

export const usePromoMutations = (
  id: string,
  queryClient: QueryClient,
  successCb: () => void,
  errorCb: (error: Error) => void,
) => {
  const add = useMutation({
    mutationFn: (data: PromoFormSchema) => createPromo(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['promos'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

  const edit = useMutation({
    mutationFn: (data: PromoFormSchema) => updatePromo(id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['promos'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

  const remove = useMutation({
    mutationFn: () => deletePromo(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['promos'],
      });
      successCb();
    },
    onError: (error) => errorCb(error),
  });

  return { add, edit, remove };
};
