import { z } from 'zod';

export const UsersSchema = z.object({
  uid: z.string(),
  displayName: z.string(),
  email: z.string(),
});

export const UsersFormSchema = z.object({
  displayName: z.string().min(1, 'Required'),
  email: z.string().min(1, 'Required'),
});

export const PasswordFormSchema = z
  .object({
    password: z.string().min(1, 'Required'),
    confirmPassword: z.string().min(1, 'Required'),
  })
  .superRefine(({ password, confirmPassword }, ctx) => {
    if (password !== confirmPassword) {
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords do not match',
        path: ['password'],
      });
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords do not match',
        path: ['confirmPassword'],
      });
    }
  });

export type UsersSchema = z.infer<typeof UsersSchema>;
export type UsersFormSchema = z.infer<typeof UsersFormSchema>;
export type PasswordFormSchema = z.infer<typeof PasswordFormSchema>;
