import { createRoute } from '@tanstack/react-router';

import { authenticatedLayout } from '@/app/router/layout';

import { newsQueryOptions } from './api';

const newsListRoute = createRoute({
  getParentRoute: () => authenticatedLayout,
  path: 'news',
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(newsQueryOptions()),
}).lazy(() => import('./NewsList').then((d) => d.Route));

export const newsRoute = newsListRoute;
