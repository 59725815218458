import Header from '@/common/Header';
import SideMenu from '@/common/SideMenu';

export function BaseAuthLayout({ children }: React.PropsWithChildren) {
  return (
    <main className="min-h-screen flex flex-col bg-slate-100">
      <Header />
      <div className="flex-1 flex overflow-y-scroll scroll">
        <SideMenu />
        <div className="flex-1">{children}</div>
      </div>
    </main>
  );
}
