import { useQuery } from '@tanstack/react-query';
import { getRouteApi, Link, Outlet } from '@tanstack/react-router';

import { hotelQueryOptions } from './api';

const route = getRouteApi('/auth/hotels/$hotelId');

export function HotelLayout() {
  const { hotelId } = route.useParams();
  const { data: hotel } = useQuery(hotelQueryOptions(hotelId));

  return (
    <>
      <div className="h-12 bg-white px-6 py-2 border-b border-slate-300">
        <h1 className="text-xl font-medium">Hotels</h1>
      </div>

      <div className="bg-white text-xs px-6 py-2 border-b border-slate-300">
        <Link to="/hotels">Hotels</Link>
        {' > '}
        <span>{hotel?.name}</span>
      </div>

      <div className="flex-1 py-4 px-6 overflow-y-scroll scroll">
        <div className="pb-2 border-b border-slate-300">
          <Link
            to="/hotels/$hotelId/about"
            params={{ hotelId: hotelId }}
            className="[&.active]:shadow-tab-link pb-3 px-4"
          >
            About
          </Link>
          <Link
            to="/hotels/$hotelId/rooms"
            params={{ hotelId: hotelId }}
            className="[&.active]:shadow-tab-link pb-3 px-4"
          >
            Rooms
          </Link>
          <Link
            to="/hotels/$hotelId/facilities"
            params={{ hotelId: hotelId }}
            className="[&.active]:shadow-tab-link pb-3 px-4"
          >
            Facilities
          </Link>
          <Link
            to="/hotels/$hotelId/attractions"
            params={{ hotelId: hotelId }}
            className="[&.active]:shadow-tab-link pb-3 px-4"
          >
            Attractions
          </Link>
          {hotelId === 'ormoc-leyte' && (
            <Link
              to="/hotels/$hotelId/events"
              params={{ hotelId: hotelId }}
              className="[&.active]:shadow-tab-link pb-3 px-4"
            >
              Events
            </Link>
          )}
        </div>

        <Outlet />
      </div>
    </>
  );
}
