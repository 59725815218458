import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useQueryClient } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { Check, X } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import {
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';

import { useHotelRoomSearchabilityMutation } from '../api';
import { RoomSchema, SearchabilityFormSchema } from '../schemas';

type RoomFormProps = {
  closeSheet: () => void;
  rooms: RoomSchema[];
};

const route = getRouteApi('/auth/hotels/$hotelId');

export default function SearchabilityForm({
  closeSheet,
  rooms,
}: RoomFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { hotelId } = route.useParams();
  const form = useForm<SearchabilityFormSchema>({
    defaultValues: Object.fromEntries(
      rooms.map((room) => [room.id, room.configuration.pax]),
    ),
  });

  const queryClient = useQueryClient();
  const updateMutation = useHotelRoomSearchabilityMutation(
    hotelId,
    queryClient,
    () => {
      toast('Success', {
        className: 'bg-green-500 text-white',
        duration: 2000,
      });
      closeSheet();
    },
    (error) => {
      toast('Something went wrong.', {
        className: 'bg-red-500 text-white',
        duration: 2500,
      });
      console.error('An error has occured: ', error);
      setIsSubmitting(false);
    },
  );

  const onSubmit = async (data: SearchabilityFormSchema) => {
    setIsSubmitting(true);
    updateMutation.mutate(data);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <SheetHeader>
          <SheetTitle>Searchability</SheetTitle>
          <SheetDescription>
            Configure what rooms will appear in search results based on the pax
            search condition.
          </SheetDescription>
        </SheetHeader>

        <table className="w-full table-fixed my-2">
          <thead>
            <tr>
              <th className="w-[15%] border px-4 py-2 text-left">Rooms</th>
              {[...Array(12).keys()].map((pax) => (
                <th
                  key={`${pax + 1}-pax-header`}
                  className="border py-2 text-center font-normal"
                >
                  <div className="text-sm font-bold">{pax + 1}</div>
                  <div className="text-xs text-slate-400">PAX</div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {/*
              Loop through rooms, create controlled component per room
              Loop through numbers 1-12 (1 for each pax)
              In database, pax is an array of strings (1-12)
            */}
            {rooms.map((room) => (
              <Controller
                key={room.id}
                control={form.control}
                name={room.id}
                render={({ field: { value, onChange } }) => (
                  <tr>
                    <td className="h-20 border px-4 py-2">
                      <p>{room.name}</p>
                      <p className="text-xs text-gray-400">
                        ({room.adults} adult
                        {Number(room.adults) > 1 && 's'}, {room.children} child
                        {Number(room.children) !== 0 && 'ren'})
                      </p>
                    </td>
                    {[...Array(12).keys()].map((pax) => {
                      // check if pax is already in the pax array for given room
                      const isEnabled = value.includes((pax + 1).toString());

                      return (
                        <td
                          key={`${room.id}-${pax + 1}-enabled`}
                          className={`h-20 border px-4 py-2 text-white cursor-pointer ${
                            isEnabled
                              ? 'bg-green-600 hover:bg-green-700'
                              : 'bg-red-600 hover:bg-red-700'
                          }`}
                          onClick={() => {
                            const tmp = new Set(value); // make a copy of array of pax and convert to Set to avoid dupes
                            if (isEnabled) tmp.delete((pax + 1).toString());
                            else tmp.add((pax + 1).toString());
                            onChange(Array.from(tmp)); // save new array of pax
                          }}
                        >
                          <div className="flex justify-center">
                            {isEnabled ? (
                              <Check className="h-5 w-5" />
                            ) : (
                              <X className="h-5 w-5" />
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                )}
              />
            ))}
          </tbody>
        </table>

        <div className="flex gap-4 items-center justify-end">
          <Button disabled={isSubmitting} type="submit" size="sm">
            {'Save'}
          </Button>
        </div>
      </form>
    </Form>
  );
}
