import axios from 'axios';
import { signOut } from 'firebase/auth';

import { auth } from '@/lib/firebase';

import { browserHistory } from './router';

export const api = axios.create({
  baseURL: `${import.meta.env.VITE_DOMAIN}/api/v1`,
  withCredentials: true,
});

// redirect to login page if unauthorized error from api
api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(JSON.stringify(error));
    if (error.response.status === 401) {
      localStorage.removeItem('userData');
      signOut(auth);
      browserHistory.replace('/login');
    }
  },
);
