import { createRoute, redirect } from '@tanstack/react-router';

import { authenticatedLayout } from '@/app/router/layout';

import { reservationsQueryOptions } from './api';

const reservationListRoute = createRoute({
  getParentRoute: () => authenticatedLayout,
  path: 'reservations',
  beforeLoad: ({ context: { auth } }) => {
    if (auth.user && auth.user.role === 'editor') {
      throw redirect({
        to: '/news',
      });
    }
  },
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(reservationsQueryOptions({})),
}).lazy(() => import('./ReservationList').then((d) => d.Route));

export const reservationsRoute = reservationListRoute;
