import { type ReactNode, createContext, useState, useEffect } from 'react';

import { signOut } from 'firebase/auth';

import { auth } from '@/lib/firebase';

export type User = { uid: string; name: string; email: string; role: string };

type LocalStorageData = { user: User; expiryDate: string };

type SetAuthFn = (user: User, expiryDate?: Date) => void;

export interface AuthContext {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  login: SetAuthFn;
  logout: () => void;
}

export const AuthContext = createContext<AuthContext | null>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(() => {
    const userData = JSON.parse(localStorage.getItem('userData')!);
    return userData?.user ?? null;
  });
  const [expires, setExpires] = useState<Date | null>(null);

  const login: SetAuthFn = (user, expiryDate) => {
    const expires =
      expiryDate ?? new Date(new Date().getTime() + 60 * 60 * 1000);
    setUser(user);
    setExpires(expires);

    localStorage.setItem(
      'userData',
      JSON.stringify({ user, expiryDate: expires.toISOString() }),
    );
  };

  const logout = () => {
    setUser(null);
    setExpires(null);
    localStorage.removeItem('userData');
    signOut(auth);
  };

  useEffect(() => {
    const userData = JSON.parse(
      localStorage.getItem('userData')!,
    ) as LocalStorageData;
    if (userData && new Date(userData.expiryDate) > new Date()) {
      login(userData.user, new Date(userData.expiryDate));
    } else logout();
  }, []);

  useEffect(() => {
    if (!expires) return;

    const remainingTime = expires.getTime() - new Date().getTime();
    const logoutTimer = setTimeout(logout, remainingTime);

    return () => clearTimeout(logoutTimer);
  }, [expires]);

  return (
    <AuthContext.Provider value={{ user, setUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
