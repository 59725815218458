import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMatch, useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

import { useUpdatePasswordMutation } from './api';
import { PasswordFormSchema } from './schemas';

export function PasswordForm() {
  // useMatch instead of useAuth here since it breaks the logout logic for some reason
  const { user, logout } = useMatch({ from: '/auth' }).context.auth;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const form = useForm<PasswordFormSchema>({
    resolver: zodResolver(PasswordFormSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const updateMutation = useUpdatePasswordMutation(
    () => {
      toast('Success', {
        className: 'bg-green-500 text-white',
        duration: 2000,
      });
      logout();
      navigate({ to: '/login', replace: true });
    },
    (error) => {
      toast('Something went wrong.', {
        className: 'bg-red-500 text-white',
        duration: 2500,
      });
      console.error('An error has occured: ', error);
      setIsSubmitting(false);
    },
    user?.uid,
  );

  const onSubmit = async (data: PasswordFormSchema) => {
    setIsSubmitting(true);
    updateMutation.mutate(data);
    form.reset();
  };

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4"
        >
          <FormField
            control={form.control}
            name="password"
            render={({ field, fieldState: { error } }) => (
              <FormItem>
                <FormLabel>New Password:</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    className={`${
                      error
                        ? 'border-2 border-red-500 focus-visible:outline-none'
                        : 'focus-visible:ring-2 '
                    }`}
                    {...field}
                  />
                </FormControl>
                <FormMessage className="text-xs" />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field, fieldState: { error } }) => (
              <FormItem>
                <FormLabel>Confirm Password:</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    className={`${
                      error
                        ? 'border-2 border-red-500 focus-visible:outline-none'
                        : 'focus-visible:ring-2 '
                    }`}
                    {...field}
                  />
                </FormControl>
                <FormMessage className="text-xs" />
              </FormItem>
            )}
          />
          <div className="w-7">
            <Button size="sm" disabled={isSubmitting}>
              Save
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
}
