import cryptoRandomString from 'crypto-random-string';

export function randomStringGenerator() {
  return (Math.random() + 1).toString(36).substring(7);
}

export function formatPrice(price: number) {
  const php = new Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency: 'PHP',
  });

  return php.format(price);
}

export function generatePassword(length: number = 8) {
  return cryptoRandomString({ length, type: 'ascii-printable' });
}
