import { Link } from '@tanstack/react-router';

import { useAuth } from '@/app/auth/useAuth';

function SideMenu() {
  const { user } = useAuth();

  return (
    <div className="bg-white w-64 flex flex-col items-start py-3 border-r border-slate-300">
      {user?.role !== 'editor' && (
        <Link
          to="/hotels"
          className="w-full [&.active]:font-bold [&.active]:bg-blue-200 px-8 py-2 hover:bg-gray-100"
        >
          Hotels
        </Link>
      )}
      {user?.role !== 'editor' && (
        <Link
          to="/reservations"
          className="w-full [&.active]:font-bold [&.active]:bg-blue-200 px-8 py-2 hover:bg-gray-100"
        >
          Reservations
        </Link>
      )}
      <Link
        to="/news"
        className="w-full [&.active]:font-bold [&.active]:bg-blue-200 px-8 py-2 hover:bg-gray-100"
      >
        News
      </Link>
      <Link
        to="/promotions"
        className="w-full [&.active]:font-bold [&.active]:bg-blue-200 px-8 py-2 hover:bg-gray-100"
      >
        Promotions
      </Link>
      <div className="w-full">
        <div className="w-full px-8 py-2">Pages</div>

        <div className="w-full flex flex-col">
          <Link
            to="/pages/terms"
            className="w-full [&.active]:font-bold [&.active]:bg-blue-200 pl-12 pr-8 py-2 hover:bg-gray-100"
          >
            Terms
          </Link>
          <Link
            to="/pages/privacy"
            className="w-full [&.active]:font-bold [&.active]:bg-blue-200 pl-12 pr-8 py-2 hover:bg-gray-100"
          >
            Privacy
          </Link>
          <Link
            to="/pages/reservation-policy"
            className="w-full [&.active]:font-bold [&.active]:bg-blue-200 pl-12 pr-8 py-2 hover:bg-gray-100"
          >
            Reservation Policy
          </Link>
        </div>
      </div>
      {user?.role === 'superadmin' && (
        <Link
          to="/users"
          className="w-full [&.active]:font-bold [&.active]:bg-blue-200 px-8 py-2 hover:bg-gray-100"
        >
          Users
        </Link>
      )}
    </div>
  );
}

export default SideMenu;
