import { useMutation } from '@tanstack/react-query';

import { api } from '@/app/api';

import type { PasswordFormSchema, UsersFormSchema } from './schemas';

//APIs

export const updateCurrentUser = async (
  data: UsersFormSchema,
  userId?: string,
) => {
  if (userId) await api.put(`/users/${userId}`, data);
};

export const updatePassword = async (
  data: PasswordFormSchema,
  userId?: string,
) => {
  if (userId) await api.put(`/users/${userId}`, data);
};

//Queries and mutations

export const useUpdateUserMutation = (
  successCb: () => void,
  errorCb: (error: Error) => void,
  id?: string,
) => {
  return useMutation({
    mutationFn: (data: UsersFormSchema) => updateCurrentUser(data, id),
    onSuccess: async () => successCb(),
    onError: (error) => errorCb(error),
  });
};

export const useUpdatePasswordMutation = (
  successCb: () => void,
  errorCb: (error: Error) => void,
  id?: string,
) => {
  return useMutation({
    mutationFn: (data: PasswordFormSchema) => updatePassword(data, id),
    onSuccess: async () => successCb(),
    onError: (error) => errorCb(error),
  });
};
