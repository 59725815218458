import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

import { hotelFacilitiesQueryOptions } from './api';
import FacilitiesForm from './forms/FacilitiesForm';
import { FacilityFormSchema, FacilitySchema } from './schemas';

const route = getRouteApi('/auth/hotels/$hotelId');

export function HotelFacilities() {
  const { hotelId } = route.useParams();
  const [formType, setType] = useState<'create' | 'update'>('create');
  const [formOpen, setFormOpen] = useState(false);
  const [facilityId, setFacilityId] = useState<string>();
  const [facility, setFacility] = useState<FacilityFormSchema>();
  const { error, data, isPending, isRefetching } = useQuery(
    hotelFacilitiesQueryOptions(hotelId),
  );

  const handleCreateFacility = () => {
    setFacilityId(undefined);
    setFacility(undefined);
    setType('create');
    setFormOpen(true);
  };

  const handleViewFacility = (facility: FacilitySchema) => {
    const { id, images, ...rest } = facility;
    setFacilityId(id);
    setFacility({ ...rest, images: images?.length ? images : [] });
    setType('update');
    setFormOpen(true);
  };

  return (
    <div className="py-4 flex flex-col gap-4">
      <div className="flex gap-2">
        <div className="flex flex-col flex-1 gap-1">
          <span className="text-lg font-medium">Facilities</span>
          <span className="text-xs">
            Create, update, and delete facilities for the hotel.
          </span>
        </div>
        <Button size="sm" onClick={handleCreateFacility}>
          Add Facilities
        </Button>
      </div>

      <Sheet open={formOpen} onOpenChange={setFormOpen}>
        <SheetContent className="sm:max-w-none w-2/3">
          <FacilitiesForm
            type={formType}
            closeSheet={() => setFormOpen(false)}
            unsetFacilityId={() => setFacilityId(undefined)}
            facilityId={facilityId}
            facility={facility}
          />
        </SheetContent>
      </Sheet>

      {(isPending || isRefetching) && 'Loading...'}
      {error && 'An error has occurred: ' + error.message}

      {!isPending &&
        !isRefetching &&
        data &&
        (data.facilities.length === 0 ? (
          <p>No Facilities</p>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="select-none">Facility</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.facilities.map((facility) => (
                <TableRow key={facility.name}>
                  <TableCell
                    className="cursor-pointer hover:bg-slate-200"
                    onClick={() => handleViewFacility(facility)}
                  >
                    {facility.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ))}
    </div>
  );
}
