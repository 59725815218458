import { useRef, useState } from 'react';
import { Control, Controller, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { zodResolver } from '@hookform/resolvers/zod';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { useQueryClient } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { X } from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import { Textarea } from '@/components/ui/textarea';
import { deleteFiles, uploadFile } from '@/lib/firebase';
import { randomStringGenerator } from '@/lib/helpers';

import { useFacilityMutations } from '../api';
import { FacilityFormSchema } from '../schemas';

type FacilitiesFormProps = {
  type: 'create' | 'update';
  closeSheet: () => void;
  unsetFacilityId: () => void;
  facilityId?: string;
  facility?: FacilityFormSchema;
};

const defaultFacility: FacilityFormSchema = {
  name: '',
  description: '',
  images: [],
};

const route = getRouteApi('/auth/hotels/$hotelId');

export default function FacilitiesForm({
  type,
  closeSheet,
  unsetFacilityId,
  facilityId,
  facility = defaultFacility,
}: FacilitiesFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const { hotelId } = route.useParams();
  const form = useForm<FacilityFormSchema>({
    resolver: zodResolver(FacilityFormSchema),
    defaultValues: facility,
  });
  const queryClient = useQueryClient();
  const { add, edit, remove } = useFacilityMutations(
    hotelId,
    facilityId!,
    queryClient,
    () => {
      toast('Success', {
        className: 'bg-green-500 text-white',
        duration: 2000,
      });
      unsetFacilityId();
      closeSheet();
    },
    (error) => {
      toast('Something went wrong.', {
        className: 'bg-red-500 text-white',
        duration: 2500,
      });
      console.error('An error has occured: ', error);
      setIsSubmitting(false);
    },
  );

  const onSubmit = async (formFacility: FacilityFormSchema) => {
    setIsSubmitting(true);

    const { images, ...fFacility } = formFacility;
    const fImages: FacilityFormSchema['images'] = [];
    if (images) {
      for (const fImg of images) {
        if (fImg.file) {
          const name = `${hotelId}-facillities-${randomStringGenerator()}-${
            fImg.file.name
          }`;
          const downloadUrl = await uploadFile(fImg.file, name);
          fImages.push({ name, url: downloadUrl });
        } else {
          fImages.push(fImg);
        }
      }
    }

    await deleteFiles(
      facility.images?.filter(
        ({ name: name1 }) =>
          !images?.some(({ name: name2 }) => name2 === name1),
      ),
    );

    if (facilityId) {
      edit.mutate({ images: fImages, ...fFacility });
    } else {
      add.mutate({ images: fImages, ...fFacility });
    }
  };

  const onDelete = () => {
    if (!isConfirmDelete) {
      setIsConfirmDelete(true);
      return;
    }
    setIsSubmitting(true);
    remove.mutate();
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="h-full flex flex-col gap-4"
      >
        <SheetHeader>
          <SheetTitle>
            Facility{type === 'update' && `: ${form.getValues('name')}`}
          </SheetTitle>
          <SheetDescription>
            <VisuallyHidden.Root>Facility form</VisuallyHidden.Root>
          </SheetDescription>
        </SheetHeader>

        <FormField
          control={form.control}
          name="name"
          render={({ field, fieldState: { error } }) => (
            <FormItem>
              <FormLabel>
                Facility Name <span className="text-red-600">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  className={`${
                    error
                      ? 'border-2 border-red-500 focus-visible:outline-none'
                      : 'focus-visible:ring-2 '
                  }`}
                  placeholder="Name"
                  autoComplete="off"
                  {...field}
                />
              </FormControl>
              <FormMessage className="text-xs" />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field, fieldState: { error } }) => (
            <FormItem>
              <FormLabel>
                Description <span className="text-red-600">*</span>
              </FormLabel>
              <FormControl>
                <Textarea
                  className={`${
                    error
                      ? 'border-2 border-red-500 focus-visible:outline-none'
                      : 'focus-visible:ring-2 '
                  }`}
                  placeholder="A brief description of the function room"
                  {...field}
                />
              </FormControl>
              <FormMessage className="text-xs" />
            </FormItem>
          )}
        />

        <FacilitiesImages control={form.control} />

        <div className="flex gap-2 items-center justify-end">
          {facilityId && (
            <>
              {isConfirmDelete && (
                <span className="ml-2 text-sm">
                  Are you sure you want to delete?
                </span>
              )}
              <Button
                type="button"
                size="sm"
                disabled={isSubmitting}
                variant="destructive"
                onClick={onDelete}
              >
                {'Delete'}
              </Button>
              {isConfirmDelete && (
                <Button
                  type="button"
                  size="sm"
                  disabled={isSubmitting}
                  variant="outline"
                  onClick={() => setIsConfirmDelete(false)}
                >
                  {'Cancel'}
                </Button>
              )}
            </>
          )}
          <Button disabled={isSubmitting} type="submit" size="sm">
            {'Save'}
          </Button>
        </div>
      </form>
    </Form>
  );
}

interface FacilitiesImagesProps {
  control: Control<FacilityFormSchema>;
}

function FacilitiesImages({ control }: FacilitiesImagesProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'images',
  });

  const onFileChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const files = target.files;
    if (!files) return;
    const images = Array.from(files).map((file) => ({
      url: URL.createObjectURL(file),
      file,
    }));
    append(images);

    fileInputRef.current!.value = '';
  };

  return (
    <div className="h-96 flex flex-col space-y-2">
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium leading-none">Images</div>
        <input
          ref={fileInputRef}
          type="file"
          name="facilityImages"
          onChange={onFileChange}
          className="hidden"
          accept="image/png, image/jpeg"
          multiple
        />
        <Button
          type="button"
          size="sm"
          onClick={() => fileInputRef.current?.click()}
        >
          Add Image
        </Button>
      </div>

      <div className="w-full h-full p-2 grid grid-cols-5 gap-2 border border-slate-400 rounded overflow-y-scroll scroll">
        {fields.length === 0 ? (
          <p className="text-sm leading-none">No files yet</p>
        ) : (
          fields.map((item, i) => (
            <Controller
              key={item.id}
              control={control}
              name={`images.${i}`}
              render={() => (
                <div className="relative h-32 w-full border border-slate-400">
                  <img
                    src={item.url}
                    className="h-full w-full object-contain"
                  />
                  <button
                    className="absolute top-1 right-1 p-1 bg-red-400 rounded-full"
                    onClick={() => {
                      URL.revokeObjectURL(item.url!);
                      remove(i);
                    }}
                  >
                    <X className="w-3 h-3" />
                  </button>
                </div>
              )}
            />
          ))
        )}
      </div>
    </div>
  );
}
