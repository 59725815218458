import * as Sentry from '@sentry/react';

import { router } from './app/router';

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: 'https://8904728f973f39b81d55a01906aec61c@o1142079.ingest.us.sentry.io/4507651269722112',
    tunnel: `${import.meta.env.VITE_DOMAIN}/tunnel`,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.tanstackRouterBrowserTracingIntegration(router),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/copenhagenresidences\.com\/api/,
      /^https:\/\/copenhagen\.ph\/api/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
