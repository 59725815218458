import { PasswordForm } from './PasswordForm';
import { UserForm } from './UserForm';

export function Settings() {
  return (
    <div className="flex flex-col">
      <div className="h-12 bg-white px-6 py-2 border-b border-slate-300">
        <h1 className="text-xl font-medium">Settings</h1>
      </div>
      <div className="flex-1 px-6 py-4 flex flex-col gap-4">
        <h2 className="font-semibold">Change Details</h2>
        <UserForm />

        <div>
          <h2 className="font-semibold mt-4">Change Password</h2>
          <p className="text-sm text-muted-foreground">
            Changing your password will log you out of your current session.
          </p>
        </div>
        <PasswordForm />
      </div>
    </div>
  );
}
