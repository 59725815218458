import { z } from 'zod';

const BaseImageArraySchema = z.array(
  z.object({ name: z.string(), url: z.string() }),
);
const BaseImageArrayFormSchema = z
  .array(
    z
      .object({
        name: z.string(),
        url: z.string(),
        file: z.instanceof(File),
      })
      .partial(),
  )
  .refine((value) => value.map((el) => el.url))
  .optional();

// Hotels
export const HotelSchema = z.object({
  id: z.string(),
  address: z.string().min(1, { message: 'Address is required' }),
  attractions: BaseImageArraySchema.max(15),
  contactNumbers: z.array(z.string().min(1)).min(1),
  emails: z.array(z.string().email()).min(1),
  events: z.object({
    description: z.string().min(1, { message: 'Description is required' }),
    images: BaseImageArraySchema.max(15),
  }),
  image: z.object({ name: z.string(), url: z.string() }),
  name: z.string().min(1, { message: 'Hotel Name is required' }).max(50),
  priority: z.number(),
  slug: z.string().min(1).max(50),
  socials: z
    .object({
      facebook: z.string(),
      messenger: z.string(),
      instagram: z.string(),
    })
    .partial()
    .optional(),
});

export const HotelFormSchema = HotelSchema.omit({
  id: true,
  attractions: true,
  events: true,
  priority: true,
  slug: true,
});

export const HotelFormSchemaExtended = HotelFormSchema.omit({
  emails: true,
  contactNumbers: true,
  image: true,
}).merge(
  z.object({
    emails: z.array(z.object({ value: z.string().email() })),
    contactNumbers: z.array(
      z.object({ value: z.string().min(1, { message: 'Cannot be empty' }) }),
    ),
    image: z.object({
      name: z.string(),
      url: z.string(),
      file: z.instanceof(File).optional(),
    }),
  }),
);

export type HotelSchema = z.infer<typeof HotelSchema>;
export type HotelFormSchema = z.infer<typeof HotelFormSchema>;
export type HotelFormSchemaExtended = z.infer<typeof HotelFormSchemaExtended>;

export type HotelResponseSchema = {
  hotels: HotelSchema[];
};

// Hotel Settings
export const HotelSettingsSchema = z.object({
  maxBookingMonths: z.coerce.string().min(1, 'Required'),
});

export type HotelSettingsSchema = z.infer<typeof HotelSettingsSchema>;

// Hotel Event Space
export const HotelEventSpaceFormSchema = z.object({
  description: z.string().min(1, { message: 'Description is required' }),
  images: BaseImageArrayFormSchema,
});

export type HotelEventSpaceFormSchema = z.infer<
  typeof HotelEventSpaceFormSchema
>;

// Hotel Attractions
export const HotelAttractionsFormSchema = z.object({
  attractions: BaseImageArrayFormSchema,
});

export type HotelAttractionsFormSchema = z.infer<
  typeof HotelAttractionsFormSchema
>;

// Rooms
export const RoomSchema = z.object({
  id: z.string(),
  name: z.string().min(1, 'Required').max(256),
  adults: z.coerce.string().min(1, 'Required'),
  children: z.coerce.string().min(1, 'Required'),
  beds: z.coerce.string().min(1, 'Required'),
  bathrooms: z.coerce.string().min(1, 'Required'),
  images: BaseImageArraySchema.max(20),
  amenities: z.array(z.string()).max(20),
  configuration: z.object({
    inventory: z.coerce.string(),
    cost: z.coerce.string(),
    available: z.boolean(),
    pax: z.array(z.string()),
  }),
});

export const RoomFormSchema = RoomSchema.omit({
  id: true,
  images: true,
  amenities: true,
  configuration: true,
  children: true, // breaks javascript as it is a reserved keyword
}).merge(
  z.object({
    child: z.coerce.string().min(1, 'Required'),
    images: BaseImageArrayFormSchema,
    amenities: z
      .array(z.object({ value: z.string().min(1, { message: 'Required' }) }))
      .min(1, 'Required'),
    configuration: z.object({
      inventory: z.coerce.string().min(1, 'Required'),
      cost: z.coerce.string().min(1, 'Required'),
      available: z.boolean().default(true),
    }),
  }),
);
export type RoomSchema = z.infer<typeof RoomSchema>;
export type RoomFormSchema = z.infer<typeof RoomFormSchema>;

export type RoomResponseSchema = {
  rooms: RoomSchema[];
};

// Facilities
export const FacilitySchema = z.object({
  id: z.string(),
  name: z.string().min(1).max(50),
  description: z.string().min(1),
  images: BaseImageArraySchema.max(20),
});

export const FacilityFormSchema = FacilitySchema.omit({
  id: true,
  images: true,
}).merge(
  z.object({
    images: BaseImageArrayFormSchema,
  }),
);
export type FacilitySchema = z.infer<typeof FacilitySchema>;
export type FacilityFormSchema = z.infer<typeof FacilityFormSchema>;

export type FacilitiesResponseSchema = {
  facilities: FacilitySchema[];
};

// Allocation
const AllocationSchema = z.record(
  z.object({
    availability: z.boolean().optional(),
    inventory: z.coerce.string().optional(),
    reservations: z.coerce.string().optional(),
  }),
);

export const AllocationFormSchema = z.object({
  hotelId: z.string(),
  roomId: z.string(),
  date: z.string(),
  availability: z.boolean().optional(),
  inventory: z.string().optional(),
  reservations: z.string().optional(),
});

export type AllocationSchema = z.infer<typeof AllocationSchema>;
export type AllocationFormSchema = z.infer<typeof AllocationFormSchema>;
export type AllocationResponseSchema = {
  allocation: AllocationSchema;
};

// Searchability / Pax / Lookup
export type SearchabilityFormSchema = Record<string, string[]>;
