import { QueryClient } from '@tanstack/react-query';
import { createBrowserHistory, createRouter } from '@tanstack/react-router';

import { loginRoute } from '@/features/auth/router';
import { hotelsRoute } from '@/features/hotels/router';
import { newsRoute } from '@/features/news/router';
import {
  pagesPrivacyRoute,
  pagesReservationPolicyRoute,
  pagesTermsRoute,
} from '@/features/pages/router';
import { promoRoute } from '@/features/promos/router';
import { reservationsRoute } from '@/features/reservations/router';
import { settingsRoute } from '@/features/settings/router';
import { usersRoute } from '@/features/users/router';

import { authenticatedLayout, indexRoute, rootRoute } from './layout';

const routeTree = rootRoute.addChildren([
  loginRoute,
  authenticatedLayout.addChildren([
    indexRoute,
    hotelsRoute,
    reservationsRoute,
    newsRoute,
    promoRoute,
    usersRoute,
    pagesPrivacyRoute,
    pagesTermsRoute,
    pagesReservationPolicyRoute,
    settingsRoute,
  ]),
]);

export const queryClient = new QueryClient();

export const browserHistory = createBrowserHistory();

export const router = createRouter({
  routeTree,
  defaultPreloadStaleTime: 0,
  context: {
    queryClient,
    auth: undefined!,
  },
  history: browserHistory,
});

// Register things for typesafety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
